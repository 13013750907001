<template>
  <v-app>

    <!--------------------------------- Toolbar -------------------------------- -->
    <v-app-bar app dense elevate-on-scroll clipped-right elevation="3">
      <v-app-bar-nav-icon @click="drawer=!drawer" v-show="!drawer"></v-app-bar-nav-icon>
      <v-img src="@/assets/logo.svg" v-show="!drawer" @click="$router.push({ name: 'admin:top'},() => {})" max-width="26" aspect-ratio="1"></v-img>
      <v-spacer class="d-sm-inline d-md-none"></v-spacer>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <NewsTicker />
      <v-toolbar-items>
        <v-menu offset-y max-width="250">
          <template v-slot:activator="{on}">
            <v-btn v-on="on" text>
              <LoginAvatar :user="loginUserInfo" :size="34" :tooltip="false" />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item two-line :to="{ name: 'admin:settings_login_show', params:{ id: loginUserInfo.id } }">
              <v-list-item-content>
                <v-list-item-title>{{ loginUserInfo.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ loginUserInfo.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(item, i) in personal_menu" :key="i" :to="item.link">
              <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-menu offset-x left max-width="180">
              <template v-slot:activator="{on}">
                <v-list-item v-on="on">
                  <v-list-item-icon><v-icon size="20">mdi-help-circle-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>サポート</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item href="https://tokoton.biz/news/" target="_blank" rel="noopener">
                  <v-list-item-icon><v-icon size="20">mdi-bell</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title>お知らせ</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item href="https://tokoton.biz/rule/" target="_blank" rel="noopener">
                  <v-list-item-icon><v-icon size="20">mdi-file-document-outline</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title>トコトン利用規約</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item href="https://tokoton.biz/contact/" target="_blank" rel="noopener">
                  <v-list-item-icon><v-icon size="20">mdi-comment-text-outline</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title>ご質問・ご要望</v-list-item-title></v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item @click="logout">
              <v-list-item-icon><v-icon size="20">mdi-logout-variant</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>ログアウト</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <!-------------------------------- SideNavi -------------------------------- -->
    <v-navigation-drawer app v-model="drawer" width="220" mobile-breakpoint="960" id="main-nav">
      <template v-slot:prepend>
          <v-img height="90" src="@/assets/logo2022_v.svg" @click="$router.push({ name: 'admin:top'},() => {})" contain class="my-4"></v-img>
      </template>
        <v-list dense nav class="mt-1">
          <template v-for="(item, i) in nav_lists"><template v-if="item.disable !== true">
            <v-list-item v-if="!item.lists" :to="item.link" exact :key="i">
              <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group v-else :key="i" v-model="item.active" :prepend-icon="item.icon" no-action color="primary">
              <template v-if="item.name" v-slot:activator>
                  <v-list-item-content><v-list-item-title>{{ item.name }}</v-list-item-title></v-list-item-content>
              </template>
              <template v-if="item.lists">
                <template v-for="(list, i) in item.lists" >
                <v-list-item v-if="list.type !== 'button'" :key="i" :to="list.link" dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="d-inline-flex align-center wrap-text">{{ list.name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-chip v-show="list.count" x-small color="primary" class="font-weight-bold">{{ list.count }}</v-chip>
                </v-list-item>
                <v-list-item v-else :key="i" class="button justify-center">
                  <!-- <v-list-item-content> -->
                    <v-btn icon color="" :to="list.link"><v-icon>{{list.icon}}</v-icon></v-btn>
                  <!-- </v-list-item-content> -->
                </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template></template>
        </v-list>
    </v-navigation-drawer>

    <!---------------------------------- Main ---------------------------------- -->
    <v-main>
      <FlashMessage />
      <router-view />
    </v-main>

    <!--------------------------------- Footer --------------------------------- -->
    <!--
    <v-footer app inset absolute>
      <small>&copy; 2013-2022 <a href="https://phonogram.co.jp" target="_blank">Phonogram</a> Inc.</small>
    </v-footer>
    -->

  </v-app>
</template>

<script>
import FlashMessage from '@/components/FlashMessage.vue'
import NewsTicker from '@/components/NewsTicker.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'
import axios from '@/plugins/axios'

const REQUEST_NAV_INDEX = 1 // 「依頼」のナビゲーションリストのインデックス
const DIRECTION_NAV_INDEX = 2 // 「制作案件」のナビゲーションリストのインデックス

export default {
  name: 'Admin',
  components: {
    FlashMessage,
    NewsTicker,
    LoginAvatar
  },
  data: function () {
    return {
      drawer: null, // for toggle navigation-drawer
      personal_menu: [],
      nav_lists: [
        {
          name: 'ダッシュボード',
          icon: 'mdi-view-dashboard',
          link: { name: 'admin:top' }
        },
        {
          name: '依頼',
          active: localStorage.getItem('nav_list_request_active') !== null ? JSON.parse(localStorage.getItem('nav_list_request_active')) : true,
          icon: 'mdi-checkbox-multiple-marked-outline',
          lists: [
            {
              name: '全て',
              link: { name: 'admin:request_index', params: { fillter: 'all' } },
              count: null
            },
            {
              name: '受付未了',
              link: { name: 'admin:request_index', params: { fillter: 'new' } },
              count: null
            },
            {
              name: '受付完了',
              link: { name: 'admin:request_index', params: { fillter: 'reviewed' } },
              count: null
            },
            {
              name: '作業中',
              link: { name: 'admin:request_index', params: { fillter: 'development' } },
              count: null
            },
            {
              name: '作業完了',
              link: { name: 'admin:request_index', params: { fillter: 'development_review' } },
              count: null
            },
            {
              name: 'テスト確認完了',
              link: { name: 'admin:request_index', params: { fillter: 'wait_to_publish' } },
              count: null
            },
            {
              name: '公開',
              link: { name: 'admin:request_index', params: { fillter: 'publish_review' } },
              count: null
            },
            {
              name: '検収',
              link: { name: 'admin:request_index', params: { fillter: 'published' } },
              count: null
            }
          ]
        },
        {
          name: '制作案件',
          icon: 'mdi-hammer-wrench',
          link: { name: 'admin:direction_index' },
          disable: true
        },
        {
          name: 'クライアント',
          icon: 'mdi-domain',
          lists: [
            {
              name: '全て',
              link: { name: 'admin:client_index', params: { fillter: 'all' } },
              count: null
            },
            {
              name: '契約中',
              link: { name: 'admin:client_index', params: { fillter: 'in_contract' } },
              count: null
            },
            {
              name: '契約満了日に近い',
              link: { name: 'admin:client_index', params: { fillter: 'near_contract_end' } },
              count: null
            },
            {
              name: '契約期間外',
              link: { name: 'admin:client_index', params: { fillter: 'not_in_contract' } },
              count: null
            }
            // {
            //   name: '新規クライアント',
            //   type: 'button',
            //   icon: 'mdi-plus-circle',
            //   link: { name: 'admin:client_new_form' }
            // }
          ]
        },
        {
          name: 'お知らせ',
          icon: 'mdi-bell-ring-outline',
          link: { name: 'admin:news_index' }
          // lists: [
          //   {
          //     name: '一覧',
          //     link: { name: 'admin:news_index' }
          //   },
          //   {
          //     name: '新規お知らせ',
          //     type: 'button',
          //     icon: 'mdi-plus-circle',
          //     link: { name: 'admin:news_new_form' }
          //   }
          // ]
        },
        {
          name: '設定',
          icon: 'mdi-cog-outline',
          lists: [
            {
              name: '保守プラン',
              link: { name: 'admin:plan_index' }
            },
            {
              name: 'サービス',
              link: { name: 'admin:point_index' }
            },
            {
              name: '営業日',
              link: { name: 'admin:holiday_index' }
            },
            {
              name: 'アカウント',
              link: { name: 'admin:settings_login_index' }
            },
            {
              name: 'システム設定',
              link: { name: 'admin:settings_status_show' }
            }
          ]
        }
      ]
    }
  },
  created: function () {
    this.reloadStatusLabel()
    this.readDataFromAPI()
    // 「制作案件」が有効なユーザーの場合、ナビゲーションリストを有効にする
    if (this.useDirectionManagement === true) {
      this.nav_lists[DIRECTION_NAV_INDEX].disable = false
    }
  },
  mounted () {
    document.body.id = 'admin'
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    },
    useDirectionManagement: function () {
      return this.$store.getters.getUseDirectionManagement
    },
    pageTitle: function () {
      return this.$route.meta.title
    }
  },
  watch: {
    nav_lists: {
      handler: function () {
        localStorage.setItem('nav_list_request_active', this.nav_lists[REQUEST_NAV_INDEX].active)
      },
      deep: true
    },
    loginUserInfo () {
      if (this.loginUserInfo) {
        this.personal_menu.push({
          name: '個人設定',
          icon: 'mdi-account-cog',
          link: { name: 'admin:settings_login_show', params: { id: this.loginUserInfo.id } }
        })
      }
    }
  },
  methods: {
    logout () {
      this.userSignOut()
    },
    reloadStatusLabel: function () {
      const navIndex = REQUEST_NAV_INDEX
      const statuses = this.$store.getters.getAllStatus
      if (statuses.filter(status => status.flag === 0).length) this.nav_lists[navIndex].lists[1].name = statuses.filter(status => status.flag === 0)[0].status
      if (statuses.filter(status => status.flag === 1).length) this.nav_lists[navIndex].lists[2].name = statuses.filter(status => status.flag === 1)[0].status
      if (statuses.filter(status => status.flag === 2).length) this.nav_lists[navIndex].lists[3].name = statuses.filter(status => status.flag === 2)[0].status
      if (statuses.filter(status => status.flag === 3).length) this.nav_lists[navIndex].lists[4].name = statuses.filter(status => status.flag === 3)[0].status
      if (statuses.filter(status => status.flag === 4).length) this.nav_lists[navIndex].lists[5].name = statuses.filter(status => status.flag === 4)[0].status
      if (statuses.filter(status => status.flag === 5).length) this.nav_lists[navIndex].lists[6].name = statuses.filter(status => status.flag === 5)[0].status
      if (statuses.filter(status => status.flag === 6).length) this.nav_lists[navIndex].lists[7].name = statuses.filter(status => status.flag === 6)[0].status
    },
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/count`)
        .then(res => {
          const navIndex = REQUEST_NAV_INDEX
          this.nav_lists[navIndex].lists[0].count = res.data.all
          this.nav_lists[navIndex].lists[1].count = res.data.new
          this.nav_lists[navIndex].lists[2].count = res.data.reviewed
          this.nav_lists[navIndex].lists[3].count = res.data.development
          this.nav_lists[navIndex].lists[4].count = res.data.development_review
          this.nav_lists[navIndex].lists[5].count = res.data.wait_to_publish
          this.nav_lists[navIndex].lists[6].count = res.data.publish_review
          this.nav_lists[navIndex].lists[7].count = res.data.published
        })
        .catch(err => {
          console.log(err)
        })
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/count`)
        .then(res => {
          const navIndex = 3
          this.nav_lists[navIndex].lists[0].count = res.data.all
          this.nav_lists[navIndex].lists[1].count = res.data.in_contract
          this.nav_lists[navIndex].lists[2].count = res.data.near_contract_end
          this.nav_lists[navIndex].lists[3].count = res.data.not_in_contract
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap-text {
  white-space: normal;
}
.news-link{
  display: flex;
  align-items: center;
   @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}
</style>
